import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const RatModels = props => {

  const { introHtml, backgroundHtml, image, captionHtml, models } = props;

  const textClass = "mb-2 text-gray-500 leading-relaxed sm:text-lg sm:leading-relaxed sm:mb-4";
  const headingClass = "text-2xl text-gray-900 font-league font-semibold mb-4 sm:text-3xl";
  const parsedIntroHtml = parseHtml(introHtml, { p: textClass });
  const parsedBackgroundHtml = parseHtml(backgroundHtml, { p: textClass });
  const parsedCaptionHtml = parseHtml(captionHtml, { p: "italic text-gray-700" });

  const availableSidb = models.filter(model => !model.in_production && !model.is_sfari);
  const production = models.filter(model => model.in_production && !model.is_sfari);
  const sfari = models.filter(model => model.is_sfari);

  return (
    <div className="bg-gray-100 page-width">
      <div className="py-12 sm:py-16">
        <h2 className="heading mb-6">Rat Models</h2>
        <div dangerouslySetInnerHTML={{__html: parsedIntroHtml}} className="mb-6 sm:mb-12" />
        <div className="flex flex-col gap-x-12 gap-y-4 mb-12 lg:flex-row">
          <div className="w-full">
            <div className={headingClass}>Background</div>
            <div dangerouslySetInnerHTML={{__html: parsedBackgroundHtml}} />
          </div>
          <div className="w-full max-w-sm flex-shrink-0 sm:w-96 sm:max-w-none">
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${image}`} alt="Rats" className="w-full h-56 object-cover mb-6" />
            <div dangerouslySetInnerHTML={{__html: parsedCaptionHtml}} />
          </div>
        </div>
        <div className="text-gray-500 flex flex-wrap gap-y-8 gap-x-24 lg:gap-x-36 xl:gap-x-48">
          <div>
            <div className={headingClass}>Available SIDB rat models</div>
            <ul>
              {availableSidb.map((model, index) => (
                <li key={index} className="leading-loose">
                  {model.url && <a href={model.url} className="link">{model.name}</a>}
                  {!model.url && model.name}
                </li>
              ))}
            </ul>
            {production.length > 0 && (
              <div className="mt-4 mb-2 text-lg text-gray-700">In production/validation:</div>
            )}
            <ul>
              {production.map((model, index) => (
                <li key={index} className="leading-loose">
                  {model.url && <a href={model.url} className="link">{model.name}</a>}
                  {!model.url && model.name}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className={headingClass}>Available SFARI rat models</div>
            <ul>
              {sfari.map((model, index) => (
                <li key={index} className="leading-loose">
                  {model.url && <a href={model.url} className="link">{model.name}</a>}
                  {!model.url && model.name}
                </li>
              ))}
            </ul>
            <a className="text-sm inline-block block-link text-gray-800 py-3.5 mt-10 px-8 sm:text-lg" href="https://www.sfari.org/resource/rat-models/">
              More on SFARI rat models
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

RatModels.propTypes = {
  introHtml: PropTypes.string.isRequired,
  backgroundHtml: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  captionHtml: PropTypes.string.isRequired,
  models: PropTypes.array.isRequired
};

export default RatModels;