import React from "react";
import Base from "./Base";
import Background from "../components/Background";
import ResearchImages from "../components/ResearchImages";
import { useDocumentTitle, useRemoteData } from "../hooks";
import Approaches from "../components/Approaches";
import WhatWeDoBlocks from "../components/WhatWeDoBlocks";
import AnimalResearch from "../components/AnimalResearch";
import YoutubeVideos from "../components/YoutubeVideos";
import RatModels from "../components/RatModels";
import Characterisation from "../components/Characterisation";

const WhatWeDoPage = () => {

  const data = useRemoteData("what-we-do/");

  useDocumentTitle("SIDB - What We Do");

  if (!data) return <Base loading={true} />;

  return (
    <Base>
      <Background html={data.background} />
      <ResearchImages images={data.images} />
      <Approaches html={data.approaches} />
      <WhatWeDoBlocks blocks={data.blocks} />
      <AnimalResearch html={data.animals} />
      <RatModels
        introHtml={data.rat_intro}
        backgroundHtml={data.rat_background}
        image={data.rat_image}
        captionHtml={data.rat_caption}
        models={data.rat_models}
      />
      <Characterisation html={data.characterisation} />
      <YoutubeVideos videos={data.videos} />
    </Base>
  );
};

WhatWeDoPage.propTypes = {
  
};

export default WhatWeDoPage;