import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const Characterisation = props => {

  const { html } = props;

  const textClass = "text-base mb-2 text-gray-500 md:text-lg";
  const hClass = "mt-8";
  const parsedHtml = parseHtml(html, { p: textClass, li: textClass, h3: hClass, h4: hClass }, true);

  return (
    <div className="page-width py-12 sm:py-16">
      <h2 className="heading mb-4">Characterisation</h2>
      <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
    </div>
  );
};

Characterisation.propTypes = {
  html: PropTypes.string.isRequired
};

export default Characterisation;