import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchIcon } from "../images/search.svg";
import { Link } from "react-router-dom";
import SearchInterface from "./SearchInterface";

const NavLinks = props => {

  const { show, setShow, moving, setMoving, isHome } = props;

  const [active, setActive] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  const ref = useRef();

  const links = [
    {
      name: "Home",
      path: "/"
    },
    {
      name: "People",
      links: [
        {
          name: "Group Leaders",
          path: "/people/group-leaders/"
        },
        {
          name: "Researchers",
          path: "/people/researchers/"
        },
        {
          name: "Professional and Scientific Staff",
          path: "/people/professional-scientific-staff/"
        },
        {
          name: "PhD Students",
          path: "/people/phd-students/"
        },
        {
          name: "Executive Team",
          path: "/people/executive-team/"
        }
      ]
    },
    {
      name: "Research",
      links: [
        {
          name: "What We Do",
          path: "/what-we-do/"
        },
        {
          name: "Research Groups",
          path: "/people/group-leaders/"
        },
        /* {
          name: "Research Themes",
          path: "/research-themes/"
        }, */
        {
          name: "Resources",
          path: "/resources/"
        },
        {
          name: "Publications",
          path: "/publications/"
        }
      ]
    },
    {
      name: "News & Events",
      path: "/news-events/"
    },
    {
      name: "Study & Work",
      links: [
        {
          name: "Vacancies",
          path: "/vacancies/"
        },
        {
          name: "SIDB PhD Programme",
          path: "/phds/"
        }
      ]
    },
    {
      name: "Contact",
      path: "/contact/"
    }
  ];

  const crossName = "w-0.5 bg-white h-full absolute group-hover:bg-primary";

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const linkClicked = link => {
    const isActive = active === link.name;
    setActive(isActive ? null : link.name);
    if (link.path) setShow(false);
  };

  const closeClicked = () => {
    setMoving(true);
    setTimeout(() => setMoving(false), 500);
    setShow(false);
  }

  const searchClicked = () => {
    setShowSearch(true);
    setShow(false);
    setActive(null);
  }

  return (
    <div className={`w-screen ${moving ? "transition-all": ""} fixed z-50 bg-gray-950 p-10 h-screen top-0 text-white ${show ? "left-0" : "-left-full"} md:static md:w-fit ${isHome ? "md:bg-transparent" : "md:bg-white"} md:text-gray-800 md:p-0 md:h-full md:tracking-wide	`}>
      <div className="absolute top-8 right-6 w-8 h-8 cursor-pointer group z-50 md:hidden" onClick={closeClicked}>
        <div className={`${crossName} rotate-45`} />
        <div className={`${crossName} -rotate-45`} />
      </div>
      <ul ref={ref} className="mt-10 divide-y divide-gray-600 md:mt-0 md:flex md:divide-none md:items-center md:h-full">
        {links.map((link, index) => {
          const isActive = active === link.name;
          const isCurrent = window.location.pathname === link.path;
          return (
            <li key={index} className="text-2xl py-3 hover:text-blue-200 list-none ml-0 group md:text-sm md:pl-5 lg:pl-8 lg:text-base md:hover:text-primary">
              <Link to={link.path} className={`relative group flex justify-between items-center md:gap-1 md:font-semibold ${isHome ? "md:text-white" : "md:text-gray-500"} lg:gap-2 ${isCurrent ? "font-semibold md:font-normal" : ""} ${isActive ? "text-blue-200 md:text-primary" : ""}`} onClick={() => linkClicked(link)}>
                {link.name}
                {link.links && (
                  <div className={`w-2.5 h-2.5 origin-center relative transition-all border-l border-b mr-4 group-hover:border-blue-200 ${isActive ? "rotate-135 top-1  border-blue-200 md:border-primary" : "-rotate-45 border-white top "} ${isHome ? "md:border-white md:group-hover:border-white" : "md:border-gray-800 md:group-hover:border-primary"} md:m-0 md:w-1.5 md:h-1.5 lg:w-2 lg:h-2`} />
                )}
                {isCurrent && (
                  <div className={`hidden md:block absolute w-full h-px -bottom-1 ${isHome ? "bg-white" : isActive ? "bg-primary" : "bg-gray-500 group-hover:bg-primary"}`} />
                )}
              </Link>
              {link.links && (
                <ul className={`mt-4 ${isActive ? "block" : "hidden"} md:absolute md:z-40 md:border md:shadow md:rounded md:py-3 md:px-6 md:bg-white`}>
                  {link.links.map((sublink, index) => {
                    const isCurrent = window.location.pathname === sublink.path;
                    return (
                      <li key={index} className={`text-lg text-gray-400 py-1 ml-4 list-none ${isCurrent ? "font-semibold md:text-primary" : ""} hover:text-blue-200 md:text-sm md:ml-0 md:text-gray-500 md:hover:text-primary`}>
                        <Link to={sublink.path} className="block" onClick={() => linkClicked(sublink)}>{sublink.name}</Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
        <div className="flex pt-6 group md:pt-0 md:ml-4 lg:ml-8" onClick={searchClicked}>
          <SearchIcon alt="Search" className={`fill-white h-auto group-hover:fill-blue-200 cursor-pointer w-6 md:w-3.5 ${isHome ? "md:group-hover:fill-white md:fill-slate-300" : "md:fill-slate-600 md:group-hover:fill-secondary"}  scale-x-[-1] lg:w-5`} />
          <div className="text-2xl ml-3 py-3 group-hover:text-blue-200 cursor-pointer list-none md:hidden">Search</div>
        </div>
      </ul>
      {showSearch && <SearchInterface setShow={setShowSearch} />}
    </div>
  );
};

NavLinks.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  moving: PropTypes.bool.isRequired,
  setMoving: PropTypes.func.isRequired,
  isHome: PropTypes.bool.isRequired
};

export default NavLinks;