import { useState, useEffect } from "react";

export const useRemoteData = path => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/${path}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => {
      if (!res.ok) return {"error": "Error fetching data"};
      return res.json()
    }).then(setData);
  }, [path]);
  return data;
}

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
}